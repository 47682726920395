.birthdayContainer {
    width: 400px;
    height: auto;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    h1 {
        font-weight: 500;
    }
    button{
        width: 100%;
        padding: 10px;
        font-size: 20px;
        color: #fff;
        border-radius: 4px;
        background-color: $color-1;
        border: none;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            background: #d85195;
        }
    }
}

@media screen and (max-width: 415px) {
    .birthdayContainer {
        width: 95%;
    }
}