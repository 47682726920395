.birthdayCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0;
    cursor: pointer;

    .birthdayCardImg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;

        img {
            height: 100%;
        }
    }
    .birthdayCardInfos {
        font-weight: 500;
        small {
            color: #656d72;
        }
    }
}


@media screen and (max-width: 415px) {
    .birthdayCard .birthdayCardImg {
        width: 50px;
        height: 50px;
    }
}